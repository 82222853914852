import React, { useRef, useEffect } from "react";
import './AboutUsOurQuote.scss';
import { Grid } from '@material-ui/core';
import Carousel from 'react-elastic-carousel';
import Item from '../TestimonialItem/Item';
import { myStyles } from './styles';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';


const AboutUsOurQuote = ({ data, backgroundImg, autoRotateEnabled }) => {
  const classes = myStyles(backgroundImg);

  const items = data?.aboutusPageCustomerTestimonials;
  // breakpoints
  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 599, itemsToShow: 1, itemsToScroll: 1 },
    { width: 904, itemsToShow: 1, itemsToScroll: 1 },
    { width: 1239, itemsToShow: 1, itemsToScroll: 1 }
  ];
  const carouselRef = useRef(null);
  let resetTimeout;
  const carouselProps = {
    breakPoints:breakPoints,
  }
  if(autoRotateEnabled){
    carouselProps.ref=carouselRef
    carouselProps.infiniteLoop = true
    carouselProps.enableAutoPlay= true
    carouselProps.autoPlaySpeed=5000
    carouselProps.isRTL=false
    carouselProps.onNextEnd=({ index }) => {
      clearTimeout(resetTimeout);
      resetTimeout = setTimeout(() => {
        carouselRef?.current?.goTo(0);
      }, 5500);
    }
  }
  const buttonEl = useRef(null);
  const registerAriaLabel = () => {
    buttonEl?.current?.querySelectorAll(".rec-dot").forEach((role) => {
      role.setAttribute('aria-label', "pagination");
    });
  }

  useEffect(() => {
    registerAriaLabel();
    if (window) {
      window.addEventListener("resize", () => { registerAriaLabel() })
    }
  }, []);
  
  return (
    // <Container maxWidth="xl" className='aboutus-our-quote-div'>
    <div ref={buttonEl}>
      <Grid container locId="aboutusPageCustomerTestimonials">
        <Container maxWidth="xl" >
          <div className={clsx("carousel-main-home-cls", classes['carousel-main-about-cls'])}>
            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="carousel-upper-cont">
              <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                <Carousel {...carouselProps}>
                  {
                    data.map((item, i) => <Item key={i} item={item} />)
                  }
                </Carousel>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Grid>
    </div>

  )
}
export default AboutUsOurQuote

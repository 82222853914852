import React from 'react';
import AboutUsHeader from '../AboutUsHeader';
import AboutUsOurQuote from '../AboutUsOurQuote';
import AboutUsAchievement from '../AboutUsAchievement';
import AboutUsOurRich from '../AboutUsOurRich';
import AboutUsOurShining from '../AboutUsOurShining';
import AboutUsOurWinning from '../AboutUsOurWinning';
import AboutUsPress from '../AboutUsPress';
import JoinOurTeam from '../JoinOurTeam';
import './AboutUsLanding.scss';
import clsx from 'clsx';
import { myStyles } from './styles';
import Container from '@material-ui/core/Container';
const AboutUsLanding = ({ data, pressData, basePath, backgroundImg, autoRotateEnabled }) => {
  const classes = myStyles(data);
  return (
    <div className="aboutus-main">
      <AboutUsHeader data={data} />
      <div className={clsx('donuts-left', classes['bg-donuts-left'])}>
        <div className={clsx('donuts-right', classes['bg-donuts-right'])}>
          <AboutUsOurRich
            richHeritageArrowImage={data?.ourRichHeritageArrowImage}
            richHeritageTileSection={data?.ourRichHeritageTileSection}
            richHeritageTitle={data?.ourRichHeritageTitle}
            backgroundImg={backgroundImg}
            autoRotateEnabled={data?.ourRichHeritageAutoRotate}
          />
          <AboutUsOurWinning
            backgroundImage={data?.ourWinningBehaviorsBackgroundImage}
            ctaLabel={data?.ourWinningBehaviorsButtonLabel}
            externalLinkUrl={data?.ourWinningBehaviorsUrl}
            longDescription={data?.winningBehaviorsLongDescription}
            titleImageShortDecription={data?.winningBehaviorsTitleShortDescriptionLogo}
            dotMatrixBackgroundImage={data?.dotMatrixBackgroundImage}
          />
          <div className={clsx('capsules-bg', classes['bg-capsules-bg'])}>
            <Container maxWidth="xl" className="aboutus-padding-div">
              <AboutUsOurShining data={data} basePath={basePath} backgroundImg={backgroundImg} />
            </Container>
          </div>
          <AboutUsOurQuote
            data={data?.employeeTestimonials}
            backgroundImg={backgroundImg}
            autoRotateEnabled={data?.employeeTestimonialsAutoRotate}
          />
        </div>
      </div>
      <Container maxWidth="xl" className="aboutus-padding-div container-padding-none">
        <AboutUsAchievement
          image={data?.employeeAwardsSection}
          backgroundImg={backgroundImg}
          autoRotateEnabled={data?.employeeAwardsSectionAutoRotate}
        />
      </Container>
      <div className="aboutus-joinus">
        <Container maxWidth="xl" className="no-gutter">
          <JoinOurTeam
            data={data?.aboutUsJoinOurTeam}
            matrixImg={data?.smallDotMatrixImage}
            basePath={basePath}
          />
        </Container>
      </div>
      <Container maxWidth="xl" className="aboutus-padding-div">
        <AboutUsPress
          pressTitle={data?.pressReleasesTitle}
          pressData={pressData}
          viewPressRoom={data?.viewPressRoom}
          basePath={basePath}
        />
      </Container>
    </div>
  );
};
export default AboutUsLanding;
